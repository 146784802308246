<template>
  <v-container fluid fill-height class="d-flex justify-start align-center flex-column pa-0">
    <div>
      <v-row dense>
        <v-col cols="12" class="text-center">
          <migration-logo />
        </v-col>
      </v-row>
      <v-row dense class="mt-1 mb-4">
        <v-col cols="12" class="text-center">
          <h2 :class="$style.title">{{ title }}</h2>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-card color="#fff" class="py-1 pa-5 mb-3 rounded-xl " rounded :class="$style.form">
            <v-card-text :class="$style.form_text">
              <h2 :class="$style.form_msg" :style="{ margin: '0 -12px' }">
                <!-- 現在ご利用のJaM idからJRE IDへアカウントの移行手続きを行います。以下のリンクからJaM idにサインインしてください。 -->
                現在ご利用のJaM idアカウントから<br>
                JRE IDアカウントへの切替手続きに伴い、<br>
                以下のリンクから<br>
                JaM idにサインインしてください。
              </h2>
              <h2 :class="$style.form_msg" style="font-weight: 600 !important;">
                <!-- アカウント移行後はJaM idをご利用できなくなります。 -->
                アカウント切替後<br>
                JaM idはご利用できなくなります。
              </h2>
              <base-btn :label="buttonName" style="font-size: 15px;" @click="onRedirect" :style="{ color: 'white', }" />
              <div class="pb-12">
              </div>
              <p :class="$style.bottom_msg" style="font-weight: 600;">JRE IDとは？</p>
              <p :class="$style.bottom_msg" :style="{ margin: '0 -12px' }">
                JR東日本グループの<br>
                様々なデジタルサービスを 1 つの ID で<br>
                ご利用いただける共通IDです。<br>
                JRE IDの詳細は<a href="https://www.jreast.co.jp/jreid/" target="_blank">こちら</a>
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" class="d-flex justify-center">
          <v-btn class="text-center" :class="$style.back" color="#FD6955" text @click="goHome">
            HOMEに戻る (Home)
          </v-btn>
        </v-col>
      </v-row> -->
    </div>
  </v-container>
</template>


<script>
export default {
  props: {
    message: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      title: "アカウント切替手続き画面",
      buttonName: 'JaM idにサインイン'
    };
  },
  methods: {
    onRedirect() {
      window.location.href = `${process.env.VUE_APP_JAMID_ENDPOINT}/entrypoint/migration`;
    }
  },
}
</script>

<style module lang="scss">
@import "@/styles/common.scss";


.title {
  font-size: 16px;
  color: #6f808c;
  font-weight: 600;
}

.form_msg {
  //font-size: 16px !important;
  font-size: 14px !important;
  color: #373e42;
  font-weight: 500;
  // text-align: left !important;
  text-align: center !important;
  margin: 22px 0px;
  line-height: 1.8rem;
}

.bottom_msg {
  font-size: 14px;
  color: #373e42;
  font-weight: 500;
  // text-align: left;
  text-align: center;
  margin-bottom: 0 !important;
}


.form {
  background-color: #ffffff;
  width: 100%;
  min-width: 320px;
  max-width: 320px;
  padding: 15px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05) !important;
}
</style>
